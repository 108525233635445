.studentBioAppFormContainer * {
  font-family: Barlow;
}

.studentBioAppFormContainer {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
}

/* Header Text Section */

.bioFormHeaderText {
  max-width: 696px;
}

.bioFormHeaderText p {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.0162em;
  color: #8a94a6;
  margin-bottom: 48px;
}

.bioFormHeaderText h2 {
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #323b4b;
  margin-bottom: 22px;
}

.studentBioAppFormContainer h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  color: #323b4b;
  margin-bottom: 24px;
}
@media screen and (max-width: 500px) {
  .studentBioAppFormContainer {
    margin-right: 0;
  }
}
