.faqItem {
  margin-bottom: 1.5rem;
  border-bottom: 1px solid #e0e0e0;
}
.activeItem {
}
.faqBtn {
  width: 100%;
  text-align: left;
  padding: 0 0 12px;
  background: transparent;
  color: #212121;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  border: none;
  cursor: pointer;
  font-weight: 500;
}
.faqBtn span {
  width: 80%;
  line-height: 1.7;
}
.faqBody {
  line-height: 2;
  width: 87%;
  overflow: hidden;
  height: 0;
  transition: height 0.3s ease-out;
  color: #616161;
}
.activeItem .faqBody {
  height: auto;
}
.inactiveItem .faqBody {
  animation-name: hideFaq;
}
.faqBody div {
  /* margin: 1.2rem 0; */
}
button.btn {
  padding-left: 0;
  background: transparent !important;
  transform: unset;
}
.btn svg {
  position: relative;
  animation: slide 1s linear;
  animation-iteration-count: infinite;
}

@keyframes slide {
  0% {
    right: 0;
  }
  50% {
    right: -1rem;
  }
  100% {
    right: 0;
  }
}
