/* Form  Styles */

.bioFormContainer {
  width: fit-content;
  margin-bottom: 163px;
  display: flex;
  flex-direction: column;
}

.textInputWrapper {
  position: relative;
  flex-wrap: wrap;
}

.deleteButton {
  position: absolute;
  right: 24px;
  top: 0px;
  cursor: pointer;
}

.textInputWrapper:not(:last-of-type) {
  border-bottom: 1px solid #e3e3e3;
  margin-bottom: 24px;
}

.textInputContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100%;
}

@media screen and (max-width: 1250px) {
  .textInputContainer {
    display: flex;
    flex-direction: column;
  }
}

.textInput {
  width: 100%;
  min-width: 372px;
}

.textInput:first-of-type:not(:only-child),
.selectBox:first-of-type:not(:only-child) {
  margin-right: 24px;
}

.selectBox {
  width: 100%;
  max-width: 372px;
}

.selectBox,
.textInput {
  margin-bottom: 24px;
}

.deleteButton {
  position: absolute;
}

/* Button Compartment */
.addUniButton {
  align-self: flex-end;
  margin-bottom: 24px;
  background-color: transparent;
  border: none;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #3913D2;
  display: flex;
  align-items: center;
}

.addUniButton svg {
  margin-right: 8px;
}

.buttonContainer {
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e3e3e3;
}

.cancelButton {
  margin-right: 10px;
}
@media screen and (max-width: 500px) {
  .bioFormContainer {
   width: unset;
  }
  .textInput {
    width: 100%;
    min-width: unset;
  }
}
