.mainWrapper * {
  transition: all 0s;
  font-family: "Barlow", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

.mainWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.backgroundWrapper {
  position: fixed;
  top: 0;
  left: 0;
  padding: 20px 100px;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.backgroundWrapper.mobile {
  background-color: #fff !important;
}

.backgroundWrapper.altNavbar {
  background-color: #fff;
  box-shadow: 0px 1px 5px rgba(225, 236, 255, 0.5);
}

.caretDown {
  margin-left: 8px;
  cursor: pointer;
}
.logo {
  width: 120px;
  height: auto;
}

@media screen and (min-width: 1440px) {
  .backgroundWrapper {
    background-color: #fff;
    box-shadow: 0px 1px 5px rgba(225, 236, 255, 0.5);
  }
}

@media screen and (max-width: 1274px) {
  .backgroundWrapper {
    padding: 20px 50px;
    padding-left: 50px;
  }
}

@media screen and (max-width: 480px) {
  .logo {
    width: 100px;
  }
  .backgroundWrapper.mobile {
    padding: 16px;
  }
}

/* 
=========================
Navbar links
=========================
*/
.allLinksWrapper {
  display: flex;
}

.allLinksWrapper.mobile > svg {
  cursor: pointer;
}

.linksContainer {
  display: flex;
  list-style: none;
  margin: 0;
  position: relative;
}

.secondaryLinks {
  margin-left: 30px;
}

.linksContainer.mobile {
  position: absolute;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  top: 100%;
  left: 0;
  width: 100%;
  height: fit-content;
  box-shadow: 0px 1px 5px rgba(225, 236, 255, 0.5);
  padding: 8px 50px;
  padding-right: 0px;
}

.linkItem {
  padding: 8px;
  margin: 0px 16px;
  position: relative;
}

.linkItem.mobile {
  margin-left: 0px;
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.linkItem:last-of-type {
  margin-right: 32px;
}

.linkItem > div,
.linkItem.mobile > div {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  height: 100%;
}
.linkItem.mobile > div {
  padding-right: 42px;
}
.logoWrap {
  display: flex;
  align-items: center;
}
.logoText {
  font-family: "Poppins";
  font-weight: 600;
  font-size: 30px;
  color: #6847ee;
}

@media screen and (max-width: 1100px) {
  .backgroundWrapper {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .logo {
    width: 100px;
  }
  .logoText {
    font-size: 24px;
  }
}
@media screen and (max-width: 1000px) {
  .linksContainer > li:last-child > div {
    background: #3913d2;
    color: #fff;
    padding: 10px;
    border-radius: 8px;
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
@media screen and (max-width: 600px) {
  .logo {
    width: 80px;
  }
  .logoText {
    font-size: 20px;
  }
}
@media screen and (max-width: 480px) {
  .linksContainer.mobile {
    padding: 16px;
    padding-right: 0px;
  }

  .linkItem.mobile > div {
    padding-right: 13px;
  }
}

.linkItem.mobile,
.linkItem.mobile > a {
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #323b4b;
}

.linkItem,
.linkItem > a {
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #323b4b;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.dropdownList {
  padding: 8px 0px;
  list-style-type: none;
  position: absolute;
  left: 50%;
  top: 100%;
  transform: translateX(-50%);
  background: #ffffff;
  box-shadow: 0px 2px 6px rgba(193, 199, 208, 0.4);
  border-radius: 8px;
}

.dropdownList.mobile {
  width: 100%;
  transform: unset;
  position: unset;
  box-shadow: none;
}

.dropListItem {
  max-width: 300px;
  padding: 8px;
  padding-left: 16px;
  display: flex;
}

.dropListItem.mobile {
  width: 100%;
  max-width: unset;
  padding: 8px;
  padding-left: 20px;
  text-align: left;
}

.dropListItem a {
  min-width: 250px;
  width: 100%;
  font-family: "Inter";
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  color: #000000;
}

.dropListItem a:hover {
  color: #3913d2;
}
