.buttonMain {
  color: white;
  border-radius: 8px;
  mix-blend-mode: normal;
  outline: none;
  border: none;
  height: fit-content;
}

.buttonMain a,
.buttonMain a:hover {
  text-decoration: none;
  color: white;
}

/* 
==========================
Button color variants
==========================
*/

.buttonMain.blue {
  background: #3913D2;
}
.buttonMain.green {
  background: #38cb89;

  border-radius: 8px;
}

/* 
==========================
Button size variants
==========================
*/

.buttonMain.small {
  padding: 8px 16px;
}

.buttonMain.medium {
  padding: 12px 40px;
}
