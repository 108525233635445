.docsFormContainer {
  margin-bottom: 8px;
  max-width: 770px;
  margin-bottom: 100px;
}

.docsFormContainer h3 {
  margin-bottom: 8px;
}

.selectBox {
  max-width: 472px;
  margin-top: 24px;
  margin-bottom: 24px;
}

.docFileInputHeader {
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.0162em;
  color: #4e5d78;
  margin-bottom: 8px;
}

.docFileInputHeader span {
  color: #b0b7c3;
}

.fieldGroupComponent {
  margin-bottom: 24px;
}

.fieldGroupComponent h4 {
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #323b4b;
  margin-bottom: 24px;
}

.fieldGroupComponent p {
  margin-bottom: 24px;
}

.fieldGroupComponent:not(:only-of-type) {
  border-bottom: 1px solid #e3e3e3;
}

/* Button Compartment */
.buttonContainer {
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e3e3e3;
}

.cancelButton {
  margin-right: 10px;
}
