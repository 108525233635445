.container {
  position: fixed;
  background: #fff;
  z-index: 1;
  /* background: linear-gradient(
    45deg,
    var(--primary-color-blue) 50%,
    #fafbfc 25%,
    #fafbfc 25%
  ); */
  background: transparent;
  right: 2rem;
  bottom: 2rem;
  width: 400px;
  /* box-shadow: 2px 0px 10px 2px rgb(202 216 245 / 62%); */
  /* border-radius: 4px; */
  /* padding: 1rem; */
}
.container img {
  width: 100%;
  border-radius: inherit;
}