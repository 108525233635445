.priceCardContainer {
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  width: 230px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(91, 127, 255, 0.1);
  border-radius: 16px;
  margin: 12px;
}

.priceCardContainer h3 {
  width: 100%;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #8a94a6;
  border-bottom: 1px solid #f3f3f3;
  padding-bottom: 16px;
  margin-bottom: 32px;
  text-align: center;
}

.priceCardContainer p {
  font-weight: bold;
  font-size: 48px;
  line-height: 58px;
  letter-spacing: 0.01em;
  color: #323b4b;
  text-align: center;
  margin-bottom: 32px;
}
