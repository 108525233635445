.linksContainer {
  position: fixed;
  top: 200px;
  left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: #fafbfc;
  border-radius: 32px;
  width: fit-content;
  min-width: 259px;
  min-height: 248px;
  padding-left: 47px;
  padding-top: 40px;
  padding-bottom: 40px;
}

.linksContainer * {
  font-family: Barlow;
}

.linkItem {
  display: flex;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #323b4b;
  list-style-type: none;
  cursor: pointer;
}

.linkItem span.linkTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #8a94a6;
}

.linkItem span.count {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f3f3f3;
  color: #8a94a6;
  width: 40px;
  height: 40px;
  border-radius: 100%;
  margin-right: 19px;
}

.linkItem.active span.linkTitle,
.linkItem.completed span.linkTitle {
  color: #323b4b;
}
.linkItem.incomplete {
  cursor: not-allowed;
}

.linkItem.active span.count {
  background: #3913D2;
  color: #fff;
}

.linkItem.completed span.count {
  background-color: #38cb89;
}
.logo1,
.logo2 {
  position: fixed;
  left: 100px;
}
.logo1 {
  top: 30px;
  left: 100px;
}
@media screen and (max-width: 900px) {
  .linksContainer {
    left: 20px;
  }
}
@media screen and (max-width: 800px) {
  .nav {
    position: fixed;
    top: 0;
    left: 0;
    padding: 1rem;
    z-index: 10;
    background: #fafbfc;
    border-radius: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  .logo1 {
    position: unset;
    width: 60px;
  }
  .linksContainer {
    flex-direction: row;
    position: unset;
    min-height: unset;
    min-width: unset;
    padding: 0;
    background: #fafbfc;
    margin: 0;
    max-width: 400px;
    margin: auto;
    width: 100%;
    margin-top: 11px;
  }
  .linkTitle {
    display: none;
  }
  .linkItem span.count {
    margin: 0 !important;
    position: relative;
    width: 30px;
    height: 30px;
    font-size: 13px;
  }
  .count::after {
    content: '';
    position: absolute;
    left: 36px;
    border: 1px dashed #8a94a8;
    width: 144px;
  }
  ul .linkItem:nth-child(3) > span::after {
    content: none;
  }
}
@media screen and (max-width: 500px) {
  .count::after {
    width: calc(50vw - 30px - 2rem);
    left: 32px
  }

}