.v_saved_list_ctrl_sec {
  /* width: 80%; */
  margin: 0 auto;
  /* max-width: 400px; */
  display: flex;
  align-items: center;

  padding: 2.5rem 0 1.5rem;
}
.v_saved_list_ctrl {
  display: flex;
  max-width: 400px;
  min-width: 400px;
  justify-content: flex-start;
}
.v_saved_list_ctrl_box {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #b0b7c370;
  width: 12%;
  margin: 0 1%;
  aspect-ratio: 1;
  font-size: 14px;
  padding: 5px;
  cursor: pointer;
  color: #323b4b;
  font-weight: 600;
  min-width: 36px;
}
.v_saved_list_ctrl span {
  display: flex;
}
.v_saved_list_ctrl_active {
  border: 1px solid #3913d2;
  color: #3913d2;
}
.v_saved_list_ctrl_end {
  background: #b0b7c350;
}
.v_saved_list_ctrl_txt {
  text-align: center;
  color: #b0b7c3;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 3rem;
}
.v_saved_list_ctrl_txt span {
  color: #8a94a6;
}
.display {
  margin-left: auto;
  font-size: 12px;
  display: flex;
  align-items: center;
}

.paginationDisplay {
  border: none;
  outline: none;
  border-radius: 0px;
  padding-left: 0;
  background: transparent;
  border-bottom: 1px solid;
  height: 36px;
  border-color: #8b87a320;
  margin-left: 12px;
  width: 60px;
}
.paginationDisplay [class*="paginationSelect__control"] {
  height: 100%;
  border-width: 0;
  border-radius: inherit;
  background: transparent;
  padding: 0;
}
.paginationDisplay:focus [class*="paginationSelect__control"] {
  border: none;
}
.paginationDisplay:hover [class*="paginationSelect__control"] {
  border: none;
}
.paginationDisplay [class*="paginationSelect__control--is-focused"] {
  border: inherit;
  box-shadow: none;
  border: none;
}
.paginationDisplay [class*="paginationSelect__indicator-separator"] {
  display: none;
}
.paginationDisplay [class*="paginationSelect__indicator"] {
  padding: 0;
}
.paginationDisplay [class*="paginationSelect__placeholder"] {
  color: #8a94a6;
  font-weight: 400;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
  overflow: hidden;
}
.paginationDisplay [class*="paginationSelect__value-container"] {
  height: 100%;
  padding-left: 0;
}
.paginationDisplay [class*="paginationSelect__single-value"] {
  color: #4e5d78;
  font-weight: 500;
  font-size: 13px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 95%;
  overflow: hidden;
}
.paginationDisplay [class*="paginationSelect__menu"] {
  z-index: 6;
  text-transform: capitalize;
}
.rightAlign {
  margin-right: 0;
}
@media (max-width: 800px) {
  .v_saved_list_ctrl_txt {
    margin-bottom: 1.5rem;
    font-size: 14px;
  }
  .v_saved_list_ctrl_sec {
    padding-top: 1.5rem;
    padding-bottom: 15px;
  }
}
@media screen and (max-width: 700px) {
  .display {
    margin-right: auto;
    margin-top: 2rem;
  }
  .v_saved_list_ctrl_sec {
    flex-direction: column;
  }
  .v_saved_list_ctrl {
    min-width: 100%;
    justify-content: center;
  }
}