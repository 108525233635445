.notice [class*="modal-dialog"] {
  max-width: 750px;
}
.notice [class*="modal-content"] {
  background-color: #fafbfc;
  background-image: url("../../../../assets/vectors/confetti.svg");
  background-position: center 100%;
  background-repeat: no-repeat;
  background-size: contain;
}
.notice [class*="modal-title"] {
  text-align: center;
  padding: 2rem 1rem 1rem;
  color: #3913D2;
  font-weight: 700;
}
.notice [class*="modal-body"] {
  padding: 1rem 2rem 3rem;
}

.txt {
  font-size: 16px;
  color: #4e5d78;
  font-weight: 500;
  text-align: center;
}
.steps {
  display: flex;
  text-align: center;
  padding-top: 1rem;
  justify-content: space-between;
}
.steps > p {
  font-size: 12px;
  width: 32%;
}
.steps > p > span {
  font-weight: 500;
  display: block;
  color: #4e5d78;
  font-size: 14px;
  margin-bottom: 8px;
}
.btn {
  width: 100%;
  height: 48px;
  margin-top: 2rem;
  border-radius: 8px;
}

@media screen and (max-width: 700px) {
  .steps {
    flex-direction: column;
  }
  .steps > p {
    max-width: 300px;
    margin: auto;
    margin-bottom: 2rem;
    width: 100%;
  }
}
