.blog {
  padding-top: 100px;
  max-width: 1366px;
}
.sectionOne {
  display: flex;
  padding-top: 2rem;
  padding-bottom: 3rem;
  align-items: center;
}
.sectionOneImg {
  width: 45%;
  max-height: 500px;
  object-fit: cover;
  object-position: top;
  border: 1px solid #f2f2f2;
}
.sectionOneInfo {
  width: 55%;
  padding-left: 2rem;
}
.sectionOneTxt1 {
  color: #6e6e6e;
  font-size: 13px;
  font-weight: 600;
  margin-bottom: 1rem;
}
.sectionOneTxt1 span {
  color: #4b4b4b;
}
.sectionOneHd {
  color: #2c2c2c;
  font-weight: 700;
  margin-bottom: 1.2rem;
  font-size: 2rem;
}
.sectionOneTxt2 {
  font-size: 15px;
  font-size: 16px;
  letter-spacing: 0.3px;
  line-height: 1.7;
}
.sectionOneTxt3 {
  display: flex;
  justify-content: space-between;
  font-size: 13px;
}
.sectionOneTxt3 span:nth-child(1) {
}
.sectionOneTxt3 span:nth-child(2) {
  color: #1473e6 !important;
  font-weight: 600;
}
.sectionOneTxt3 a {
  color: #1473e6;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.sectionOneTxt3 a svg {
  width: 18px;
  margin-left: 4px;
}
.sectionTwo {
  padding-top: 3rem;
  padding-bottom: 3rem;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 3rem;
}
.sectionTwoItem {
}
.sectionTwoImg {
  width: 100%;
  max-height: 220px;
  object-fit: cover;
  min-height: 220px;
  object-position: top;
}
.sectionTwoInfo {
  width: 100%;
  padding-left: 0;
  padding-top: 1rem;
}
.sectionTwoItem .sectionOneTxt2 {
  font-size: 14px;
  max-height: 75px;
  overflow: hidden;
}
.sectionTwoItem .sectionOneHd {
  font-size: 1rem;
  margin-bottom: 14px;
}
.sectionTwoItem .sectionOneTxt3 {
  font-size: 12px;
}
.sectionTwoItem .sectionOneTxt1 {
  font-size: 11.5px;
}
.doubleGrid {
  grid-template-columns: 1fr 1fr;
}
.sectionFourItem {
}
.reverseRow {
  flex-direction: row-reverse;
}
.reverseRow .sectionOneInfo {
  padding-left: 0;
  padding-right: 2rem;
}

@media screen and (max-width: 800px) {
  .sectionOne {
    flex-direction: column;
  }
  .sectionOneImg {
    width: 100%;
    border: none;
  }
  .sectionOneInfo {
    width: 100%;
    padding-left: 0;
    padding-top: 1rem;
  }
  .sectionTwo,
  .doubleGrid {
    grid-template-columns: 1fr;
  }
}
