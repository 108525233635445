.dragContainer {
  background: #fafbfc;
  border: 1px dashed #c1c7d0;
  border-radius: 12px;
  min-height: 150px;
  max-width: 472px;
  border-radius: 12px;
  padding: 16px;
  padding-top: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.uploadInfo {
  display: flex;
  flex-direction: column;
  margin-top: 16px;
}

.uploadInfo p {
  max-width: 350px;
  margin-bottom: 0px !important;
  letter-spacing: 0.0326em;
  color: #b0b7c3;
  font-weight: 500;
  font-size: 11px;
  line-height: 18px;
}
.uploadInfo p span {
  color: #3913D2;
  font-weight: 600;
}
.uploadInfo p span.red {
  color: #ff5630;
}

.uploadInfo.active {
  border-color: #323b4b;
}

.selectFilesButton {
  background: #f3f3f3;
  border-radius: 4px;
  border: none;
  padding: 8px;
  margin-bottom: 18px;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.01em;
  color: #323b4b;
}

/* File list */
.fileList {
  margin-top: 24px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 0px;
  max-width: calc(464px + 16px);
}

.fileListItem {
  width: 232px;
  height: 50px;
  background: #ebefff;
  border: 1px solid #3913D2;
  border-radius: 12px;
  display: flex;
  align-items: center;
  padding-left: 30px;
  font-size: 14px;
  line-height: 17px;
  color: #3913D2;
  margin-bottom: 4px;
  position: relative;
}

.fileListItem:nth-of-type(n) {
  margin-right: 8px;
}

.fileListItemCloseIcon {
  position: absolute;
  right: 30px;
  cursor: pointer;
}

.fileListItemIcon {
  margin-right: 10px;
}
