.v-terms .svg {
  position: absolute;
  right: 1rem;
  top: 1rem;
  width: 24px;
}
.v-terms [class*="modal-dialog"] {
  max-width: 650px !important;
}
.v-terms [class*="modal-content"] {
  padding: 1rem;
}
.v-terms [class*="modal-title"] {
  color: #323b4b;
  font-weight: 800;
  font-size: 1.5rem;
  text-align: center;
  padding: 2rem 1rem 0;
}
.v-terms p {
  line-height: 26px;
}
.v-terms a {
  color: #3913D2 !important;
  text-decoration: underline !important;
}
.v-terms h6 {
  color: #323b4b;
  font-weight: 600;
  font-size: 18px;
  margin-top: 1.5rem;
  margin-bottom: 1.2rem;
}
.v-terms ol h6 {
  margin-left: -2rem;
}
.v-terms li {
  margin-bottom: 8px;
  line-height: 24px;
}
.v-terms button {
  width: 100%;
  margin: 2rem auto;
  height: 50px;
  border: none;
  border-radius: 12px;
  color: #fff;
  background: #3913D2;
  font-size: 16px;
}
.v-terms-roman {
  list-style-type: lower-roman;
}
.v-terms-alpha {
  list-style-type: lower-alpha;
}
.v-terms-grid {
  display: grid;
  grid-template-columns: 1fr 4fr;
}
.v-terms-grid div {
  border: 1px solid #000;
  padding: 5px;
}

@media (max-width: 600px) {
  .v-terms .modal-content {
    padding: 8px;
  }
  .v-terms button {
    margin: 1rem auto;
  }
  .v-terms .modal-title {
    font-size: 1.3rem;
  }
  .v-terms h6 {
    font-size: 16px;
  }
  .v-terms button {
    font-size: 14px;
  }
}
