.selectbox_wrapper {
  width: 100%;
  position: relative;
}

.selectbox_wrapper * {
  font-family: Barlow;
}

/* 
============================================
SELECT DROPDOWN CONTAINER STYLES
============================================
*/

.selectbox_container {
  border: 1px solid #c1c7d0;
  border-radius: 12px;
  position: relative;
  height: 56px;
  width: 100%;
  padding: 18px 16px;
  cursor: pointer;
  transition: background 0.3s ease-in-out;
  display: flex;
  align-items: center;
}

.selectbox_container_active {
  border-color: #969696;
}

.selectbox_container:hover {
  background-color: #99999910;
}

.selectbox_container .selectedItem {
  font-size: 14px;
  line-height: 100.9%;
  display: flex;
  align-items: center;
}

.selectbox_container.lang .selectedItem svg {
  margin-right: 17px;
}

.selectbox_container_active .caret_down_icon {
  transform: rotate(180deg) translateY(40%);
}

.caret_down_icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 22px;
  transition: transform 0.3s ease-in-out;
}

.selectbox_container.lang .caret_down_icon path {
  stroke: var(--ssr-color-white);
}

.placeholder {
  position: absolute;
  font-size: 14px;
  line-height: 100.9%;
  color: #c4c4c4;
  left: 18px;
  top: 50%;
  transform: translateY(-50%);
}

.selectedItem {
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.0162em;
  color: #323b4b;
}

/* 
=======================================
DROPDOWN LIST
=======================================
*/

.dropdown_list {
  width: 100%;
  height: fit-content;
  max-height: 240px;
  list-style-type: none;
  position: absolute;
  top: calc(100% + 4px);
  border: 1px solid #f3f3f3;
  box-shadow: 0px 12px 24px rgba(92, 147, 226, 0.05);
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 8px;
  padding: 16px;
  overflow-y: auto;
  z-index: 10;
}

.dropdown_list_item {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.0162em;
  color: #969696;
  cursor: pointer;
  padding: 12px 8px;
  text-transform: capitalize;
}

.active_item {
  background-color: #fafbfc;
}

.dropdown_list_item:hover {
  background: #fafbfc;
}


.formInputSelect {
  height: 56px;
  border: 1px solid #c1c7d0;
  border-radius: 12px;
  /* padding: 18px 16px; */
}
.formInputSelect [class*="formSelect__control"] {
  height: 100%;
  border-width: 0;
  border-radius: inherit;
  background: transparent;
  padding: 0;
}
.formInputSelect:focus [class*="formSelect__control"] {
  border: none;
}
.formInputSelect:hover [class*="formSelect__control"] {
  border: none;
}
.formInputSelect [class*="formSelect__control--is-focused"] {
  border: inherit;
  box-shadow: none;
  border: none;
}
.formInputSelect [class*="formSelect__indicator-separator"] {
  display: none;
}

.formInputSelect [class*="formSelect__placeholder"] {
  color: #b0b7c3;
}

.formInputSelect [class*="formSelect__value-container"] {
  height: 100%;
  text-transform: capitalize;
  padding-left: 1rem;
}
.formInputSelect [class*="formSelect__menu"] {
  z-index: 6;
  text-transform: capitalize;
}
@media screen and (max-width: 500px) {
  .selectbox_wrapper {
    margin-right: 0 !important;
  }
}