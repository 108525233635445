.audioContainer {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 8px;
  background: #3913D2;
  border-radius: 100px;
  flex: none;
  flex-direction: row-reverse;
}
.audioContainer > div {
  box-shadow: none !important;
}
.audioAvatar {
  margin-right: 16px;
  display: none;
}

.audioAvatar img {
  object-fit: cover;
}

.audioPlayIcon {
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  background-color: #fff;
  margin-left: 16px;
  margin-right: 8px;
  cursor: pointer;
  display: none;
}
.audioWaves img {
  max-height: 60px;
}