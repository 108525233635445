/* FAQ */
.faqBackground {
  background: #fafafa;
}
.faqMainContainer {
  position: relative;
  padding: 40px 0 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.faqMainContainer h1 {
  font-weight: bold;
  font-size: 48px;
  line-height: 64px;
  text-align: center;
  color: #323b4b;
  margin-bottom: 64px;
}

.faqQuestionsContainer {
  /* padding-left: 100px; */
  padding-right: 100px;
  width: 100%;
  height: fit-content;
  display: flex;
  overflow-x: scroll;
  scroll-behavior: smooth;
  margin-bottom: 2rem;
  padding-left: calc(50% - 750px);
}

.faqQuestion {
  padding: 32px 32px 10px;
  display: flex;
  background-color: #fff;
  flex-direction: column;
  min-width: 370px;
  min-height: 300px;
  max-height: 300px;
  height: fit-content;
  background: #ffffff;
  box-shadow: 0px 8px 10px rgba(91, 127, 255, 0.15);
  border-radius: 16px;
  margin: 12px;
}

.faqQuestionHeader {
  display: flex;
  padding-bottom: 8px;
  border-bottom: 1px solid #e6e6e6;
  margin-bottom: 24px;
}

.faqQuestionHeader h3 {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #4d4d4d;
  margin-left: 16px;
  margin-bottom: 0px;
}

.faqQuestion p {
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.0162em;
  color: #999999;
  max-height: 200px;
  overflow-y: auto;
}

.feedbackNavArrow {
  /* position: absolute;
  display: flex;
  bottom: 64px; */
}

.feedbackNavArrow svg {
  cursor: pointer;
  margin: 20px;
  margin-bottom: 0px;
}

.feedbackNavArrow svg.disabled {
  cursor: not-allowed;
}
.feedbackNavArrow svg.disabled path,
.feedbackNavArrow svg.disabled rect {
  stroke: #b0b7c3;
}
@media screen and (max-width: 1366px) {
  .faqQuestionsContainer {
    padding-left: 3rem;
  }
}
@media screen and (max-width: 480px) {
  .faqQuestionsContainer {
    padding-left: 16px;
  }
  .faqQuestionsContainer {
    padding-left: 0;
  }
  .faqQuestion {
    min-width: 80vw;
    padding: 30px 1rem 10px;
  }
  .faqMainContainer {
    padding-bottom: 3rem;
  }
  .faqMainContainer h1 {
    font-size: 2.2rem;
    margin-bottom: 2rem;
  }
}
@media screen and (max-width: 320px) {
  .feedbackNavArrow {
    bottom: 10px;
  }
}
