/* Form  Styles */

.bioFormContainer {
  width: fit-content;
  margin-bottom: 163px;
}

.textInputContainer {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.textInput {
  width: 100%;
  min-width: 372px;
  margin-bottom: 24px;
}

.textInput:first-of-type:not(:last-child) {
  margin-right: 24px;
}

.selectBox {
  width: 100%;
  max-width: 372px;
  margin-right: 24px;
  margin-bottom: 24px;
}

/* Button Compartment */
.buttonContainer {
  padding-top: 24px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid #e3e3e3;
}

.cancelButton {
  margin-right: 10px;
}
@media screen and (max-width: 500px) {
  .bioFormContainer {
   width: unset;
  }
  .textInput {
    min-width: unset;
  }
}
