.boxContainer {
  display: flex;
  flex-direction: column;
  width: 230px;
  padding: 16px;
  height: fit-content;
}

.boxContainer.fullWidth {
  width: 100%;
}

.boxContainer.fullWidth p {
  width: 100% !important;
}

.boxContainer * {
  font-family: "Barlow", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

.boxContainer .icon {
  width: 33px;
  height: 33px;
}

.boxContainer h3 {
  width: 190px;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #323b4b;
  margin: 8px 0px;
  margin-top: 16px;
}

.boxContainer p {
  width: 230px;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: #8a94a6;
}

.boxContainer a {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.02em;
  color: #3913D2;
}

@media screen and (max-width: 480px) {
  .boxContainer {
    margin-top: 20px;
    width: 100%;
    padding-left: 0px;
    margin-right: unset !important;
  }
  .boxContainer p {
    width: 100%;
    font-size: 14px;
  }
}

/* 
=============================
BOX WITHOUT LINK
=============================
*/

.boxContainer.boxWithoutLink {
  border: 1px solid #f3f3f3;
  background: #ffffff;
  border: 1px solid #f3f3f3;
  border-radius: 12px;
  margin: 16px;
}

.boxContainer.boxWithoutLink p {
  width: 180px;
}

.boxContainer.boxWithoutLink.boxWithShadow {
  border: none;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(193, 199, 208, 0.1);
  border-radius: 12px;
}

@media screen and (max-width: 480px) {
  .boxContainer.boxWithoutLink {
    width: 100%;
  }
  .boxContainer.boxWithoutLink p {
    width: 80%;
  }
}
