.section-b-footer {
  margin-top: 64px !important;
  background: #fff;
  margin-bottom: 0 !important;
}
.section-b-footer ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.section-b-footer h4 {
  color: #000;
  font-weight: 700;
}

.footer-box-a p {
  font-size: 16px;
  font-weight: 300;
}

.footer-input {
  border-radius: 8px;
}

.footer-input input {
  border: none;
  background: transparent;
  background: #f3f3f3;
  height: 46px;
  padding: 12px 16px;
  border-radius: 8px;
  width: 100%;
  margin-right: 8px;
  outline: none;
}

.footer-input input::placeholder {
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 0.0324em;
  color: #8a94a6;
}

.footer-input input::placeholder {
  color: #4e5d78;
}

.footer-input button {
  border: none;
  background: #3913D2;
  border-radius: 8px;
  color: #fff;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* .footer-box-b {
  color: #323b4b;
} */

.footer-box-b p {
  font-size: 16px;
}

.footer-box-b li:first-child {
  margin-bottom: 16px !important;
}
.footer-box-b li:not(:first-child) {
  margin-bottom: 8px;
}

.socialicon {
  display: flex !important;
  width: fit-content;
  justify-content: space-between;
}
.socialicon img {
  width: 18px;
  height: 18px
}
.socialicon > span {
  margin-right: 12px;
}
.e-mail {
  font-weight: 600;
  color: #3913D2;
}

.footerLinksContainer li:not(:first-of-type),
.footerCompanyStatement,
.subscribeContainer p {
  font-family: "Barlow";
  font-weight: 500;
  font-size: 16px;
  line-height: 27px;
  letter-spacing: 0.0162em;
  color: #8a94a6;
}
.subscribeContainer p {
  font-weight: 400;
}
.subscribeContainer h4 {
  color: #323b4b;
}

.footerLinkHeader {
  font-weight: 600;
  font-size: 18px;
  line-height: 138.02%;
  letter-spacing: 0.02em;
  color: #323b4b;
}

.footerLink {
  cursor: pointer;
  font-weight: 500;
  font-size: 15px;
}

.section-b-footer h6 {
  font-size: 16px;
  color: #8a94a6;
  font-weight: 400;
  margin-top: 32px !important;
}
.logo {
  width: 120px;
  margin-bottom: 4px;
}

.footerCompanyStatement {
  max-width: 220px;
}
.footerLinksWrapper1 {
  width: 50%;
  display: flex;
  justify-content: space-between;
  margin: auto;
}
.footerLinksContainer li:hover,
.footerLinksContainer a:hover {
  color: #3913D2;
}
@media screen and (max-width: 1000px) {
  .socialicon {
    display: none !important;
  }
}
@media screen and (max-width: 800px) {
  .logo {
    width: 100px;
    margin-bottom: 1rem;
    margin-top: 3rem;
  }
  .footer-box-b {
    flex-direction: column;
  }
}
@media screen and (max-width: 570px) {
  .footerLinksWrapper,
  .footerLinksWrapper ul {
    width: 100%;
    flex-direction: column !important;
    align-items: flex-start !important;
    align-content: unset !important;
  }

  .footerLinksContainer li:not(:first-of-type),
  .footerCompanyStatement,
  .subscribeContainer p {
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.0162em;
    color: #8a94a6;
  }

  .section-b-footer ul li,
  .footerCompanyStatement,
  .mobileSocialLinks,
  .subscribeContainer h4,
  .subscribeContainer p {
    text-align: left !important;
    width: fit-content;
  }

  .footerCompanyStatement {
    max-width: unset;
    width: 100%;
  }

  .subscribeContainer,
  .footer {
    padding-left: 0px !important;
  }

  .footerVobbBrief {
    align-items: flex-start;
  }
}
