.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  position: static;
  width: 280px;
  min-height: 344px;
  border-radius: 16px;
  margin: 24px;
  background: #ffffff;
  border-radius: 16px;
  padding-top: 45px;
}

.cardContainer * {
  font-family: "Barlow", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

.cardIcon {
  margin-bottom: 40px;
  width: 80px;
  height: 80px;
}

.cardContainer h3 {
  font-weight: 600;
  font-size: 18px;
  line-height: 22px;
  letter-spacing: 0.01em;
  color: #323b4b;
  margin-bottom: 16px;
}

.cardContainer p {
  width: 239.33px;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #8a94a6;
}

.cardLink,
.cardLink:hover {
  font-weight: 500;
  color: #3913D2;
}

@media screen and (max-width: 480px) {
  .cardContainer {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    min-width: 300px;
    margin-bottom: 48px;
  }
  .cardContainer p {
    font-size: 14px;
  }
}
