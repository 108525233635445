/* Feedback Section */
.feedbackSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 146px;
}

.feedbackSection h1 {
  max-width: 460.5px;
  font-style: normal;
  font-size: 36px;
  line-height: 44px;
  text-align: center;
  letter-spacing: 0.01em;
  color: #000000;
  margin-bottom: 64px;
  font-weight: 700;
}

.feedbackCards {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  position: relative;
}

.feedbackNavArrow {
  position: absolute;
  display: flex;
  bottom: 64px;
}

.feedbackNavArrow svg {
  cursor: pointer;
  margin: 20px;
  margin-bottom: 0px;
}

.feedbackNavArrow svg.disabled {
  cursor: not-allowed;
}
.feedbackNavArrow svg.disabled path,
.feedbackNavArrow svg.disabled rect {
  stroke: #b0b7c3;
}

@media screen and (max-width: 700px) {
  .feedbackSection {
    padding: 16px;
  }
}

@media screen and (max-width: 480px) {
  .feedbackSection h1 {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 40px;
  }
  .feedbackSection {
    margin-bottom: 5rem;
  }
    .feedbackNavArrow {
    bottom: 40px;
  }
}

/* @media screen and (max-width: 320px) {
  .feedbackNavArrow {
    bottom: 10px;
  }
} */
