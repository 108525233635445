.pageWrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin: 0 auto;
}

.pageWrapper > div {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.pageWrapper h1,
.fopageWrappernt h2,
.pageWrapper h3,
.pageWrapper h4,
.pageWrapper h5,
.pageWrapper h6,
.pageWrapper p {
  font-family: "Barlow", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
    sans-serif;
}

.font {
  font-family: "Barlow", "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS",
  sans-serif;
}