.button {
  background-color: transparent;
  outline: none;
  border: none;
  font-family: Barlow;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  padding: 14px 16px;
  border-radius: 8px;
}

.button.secondary {
  color: #4e5d78;
}

.button.secondary:hover {
  background-color: #fafbfc;
}

.button.primary {
  background: #3913D2;
  color: white;
}

.button.primary:disabled {
  cursor: not-allowed;
  background-color: #f3f3f3;
  color: #c1c7d0;
}

.button.primary:hover:not(:disabled) {
  background-color: #3913D2f3;
}
